import { snakeCase } from 'lodash';

import {
  AddSignatoryFormHelpers,
  AddSignatoryFormValues,
  EditSignatoryActionProps,
} from 'signatories/types';
import { CompanySignatoryDetailModel, CompanySignatoryListItemModel } from 'signatories/models';
import { convertKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import { SetDisabled, SetStateType } from 'types';
import { signFormTransaction, signTransaction } from 'utils/signTransaction';
import axios from 'core/axios';
import { getSignatoryEditData, getSignatureWeight } from '../utils';

const baseSignatoriesUrlPath = '/signatories/';

export const getSignatories = () => (
  axios.get(getApiUrl(baseSignatoriesUrlPath))
    .then(({ data }) => CompanySignatoryListItemModel.fromResponse(data))
    .catch(console.error)
);

export const addSignatory = (values: AddSignatoryFormValues, actions: AddSignatoryFormHelpers) => {
  const { signatory, signatureType, ...restProps } = values;

  if (typeof signatory === 'undefined') {
    throw new Error("Signatory wasn't provided");
  }

  const data = {
    ...convertKeysCase(restProps, snakeCase),
    legal_entity_id: signatory.id,
    signature_type: getSignatureWeight(signatureType),
  };

  return signFormTransaction({
    urlPath: baseSignatoriesUrlPath,
    navigatePath: baseSignatoriesUrlPath,
    data,
    actions,
  });
};

export const getSignatoryDetails = (signatoryId: string) => (
  axios.get(getApiUrl(`${baseSignatoriesUrlPath}${signatoryId}/`))
    .then(({ data }) => CompanySignatoryDetailModel.fromResponse(data))
    .catch(console.error)
);

export const editSignatoryRole = (
  {
    signatoryId,
    values,
    actions,
  }: EditSignatoryActionProps,
) => {
  const data = getSignatoryEditData(values);

  return axios.post(getApiUrl(`${baseSignatoriesUrlPath}${signatoryId}/update/role/`), data)
    .then(
      ({ data: _respData }) => { window.location.reload(); },
      ({ response: { data: errors } }) => { parseErrorResponse(errors, actions); },
    )
    .catch((error) => {
      console.error(error);
      actions.setSubmitting(false);
    });
};

export const editSignatorySignatureType = (
  {
    signatoryId,
    values,
    actions,
  }: EditSignatoryActionProps,
) => {
  const data = getSignatoryEditData(values);

  return signFormTransaction({
    urlPath: `${baseSignatoriesUrlPath}${signatoryId}/update/signature`,
    navigatePath: `${baseSignatoriesUrlPath}${signatoryId}`,
    data,
    actions,
  });
};

export const removeSignatory = (
  signatoryId: number,
  setDisabled: SetDisabled,
  setPageMessages: SetStateType<string[]>,
) => (
  signTransaction({
    urlPath: `${baseSignatoriesUrlPath}${signatoryId}/remove`,
    navigatePath: baseSignatoriesUrlPath,
    setDisabled,
    setPageMessages,
  })
);
