import { FormikHelpers } from 'formik';

import { catchHandler } from 'core/utils';
import { getUrl, getApiUrl } from 'utils/urls';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { SetDisabled } from 'types';
import axios from 'core/axios';

type BaseSignTransactionProps<T> = {
  /**
   * Submit url path
   */
  urlPath: string;
  /**
   * Path to which to navigate after successful transaction signing
   */
  navigatePath?: string;
  /**
   * Data to be sent to the server
   */
  data?: T;
};

type SignTransactionProps<T> = BaseSignTransactionProps<T> & {
  /**
   * Function to set error messages on the page in case of an error response
   */
  setPageMessages?: (messages: string[]) => void;
  /**
   * Function to set the disabled state of the submit button
   */
  setDisabled: SetDisabled;
};

export const signTransaction = <DataType extends object>(
  {
    urlPath,
    navigatePath,
    setDisabled,
    data,
    setPageMessages,
  }: SignTransactionProps<DataType>,
) => (
    axios.post(getApiUrl(urlPath), data)
      .then(({ data: { tx_hash: txHash } }) => {
        const redirectUrl = navigatePath ? getUrl(navigatePath) : undefined;
        handleTransactionCreation(txHash, redirectUrl);
        setDisabled(false);
      })
      .catch((error) => {
        console.error(error);
        if (setPageMessages) {
          const { response: { data: { messages } } } = error;
          messages && setPageMessages(messages);
        }
        setDisabled(false);
        return Promise.reject();
      })
  );

type SignFormTransactionProps<DataType, FormikHelpersType> = BaseSignTransactionProps<DataType> & {
  /**
   * Formik actions object
   */
  actions: FormikHelpers<FormikHelpersType>;
};

export const signFormTransaction = <DataType extends object, FormikHelpersType extends object>(
  {
    urlPath,
    navigatePath,
    data,
    actions,
  }: SignFormTransactionProps<DataType, FormikHelpersType>,
) => (
    axios.post(getApiUrl(urlPath), data)
      .then(({ data: { tx_hash: txHash } }) => {
        const redirectUrl = navigatePath ? getUrl(navigatePath) : undefined;
        handleTransactionCreation(txHash, redirectUrl);
        actions.setSubmitting(false);
      })
      .catch((error) => catchHandler(error, actions))
  );
