import convertResponseToModel from 'utils/responseToModel';
import { convertKeysToCamelCase } from 'core/utils';
import PricingPlanItemModel from './pricingPlanItem';

/**
 * Pricing Plan Model
 *
 * Props under restProps are:
 * 'id', 'name', 'tier', 'isCustom', isCustomizable, 'isSubscribed'
 */
export default class PricingPlanModel {
  constructor({ pricingItems, ...restProps }) {
    this.pricingItems = convertResponseToModel(pricingItems, PricingPlanItemModel);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  static fromResponse(data) {
    const convertedCase = convertKeysToCamelCase(data);
    return new PricingPlanModel(Object.fromEntries(
      Object.entries(convertedCase).filter(([_, v]) => (v != null)), // remove null values before converting to model
    ));
  }

  static fromArrayResponse(data) {
    return data.map((doc) => this.fromResponse(doc));
  }
}
