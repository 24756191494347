import { Formik } from 'formik';

import { convertBooleanString } from 'utils/convertBooleanString';
import { ContactSelectField, BaseContactSelectFieldProps } from './selectField';
import { HiddenInput } from './hiddenInput';

// All props are strings because it's used only for django views where properties are passed via data attribute
interface ContactSelectFieldFormProps extends BaseContactSelectFieldProps {
  /**
   * The field ID
   */
  inputId?: string;
  /**
   * Indicates whether the field is optional
   */
  isOptional?: string;
  /**
   * Indicates whether the field is disabled
   */
  isDisabled?: string;
  /**
   * Indicates whether to include company wallets
   */
  includeCompanyWallets?: string;
  /**
   * Indicates whether to include own contacts
   */
  includeOwn?: string;
  /**
   * Indicates whether to exclude custodial contacts
   */
  excludeCustodial?: string;
  /**
   * Indicates whether to show portfolios
   */
  withPortfolios?: string;
}

export const ContactSelectFieldForm = ({
  inputId,
  name,
  isOptional,
  isDisabled,
  includeOwn,
  serviceType,
  includeCompanyWallets,
  selectContactLabel,
  excludeCustodial,
  emptyValueLabel,
  withPortfolios,
  includeOnly,
}: ContactSelectFieldFormProps) => {
  const isOptionalBoolean = convertBooleanString(isOptional);
  const withPortfoliosBoolean = convertBooleanString(withPortfolios);

  return (
    <Formik
      initialValues={{ [name]: {} }}
      enableReinitialize
      // Here the purpose of formik wrapper is to have formik context for the ContactSelectField
      // in django form where submission is handled by django form, but the onSubmit prop is required by formik
      onSubmit={() => {}}
    >
      {() => (
        <>
          <HiddenInput
            id={inputId}
            name={name}
            isOptional={!isOptionalBoolean}
            withPortfolios={withPortfoliosBoolean}
          />
          <ContactSelectField
            id={inputId}
            name={name}
            isOptional={isOptionalBoolean}
            isDisabled={convertBooleanString(isDisabled)}
            includeOwn={convertBooleanString(includeOwn)}
            serviceType={serviceType}
            includeOnly={includeOnly}
            includeCompanyWallets={convertBooleanString(includeCompanyWallets)}
            selectContactLabel={selectContactLabel || undefined}
            excludeCustodial={convertBooleanString(excludeCustodial)}
            emptyValueLabel={emptyValueLabel || undefined}
            withPortfolios={withPortfoliosBoolean}
          />
        </>
      )}
    </Formik>
  );
};
