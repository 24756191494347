import { SimpleDocumentModel } from 'media/models/document';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';

/**
 * Legal Entity Profile Model
 *
 * Props under restProps are:
 * 'id', 'name', 'email', 'status', 'statusMessage', 'baseCurrency', 'timeZone', 'language', 'extensionsEnabled', 'phoneNumber',
 * 'emailNotificationsEnabled', 'city', 'postalCode', 'street', 'additionalAddress', 'county', 'country',
 * 'walletType', 'homepage', 'jurisdiction', 'companyType', 'walletAddress', 'registeredBy'
 */
class ProfileModel {
  constructor({
    bankAccounts, dateOfIncorporation, dateOfBirth,
    documents, avatar, ...restProps
  }) {
    this._avatar = new LegalEntityAvatarModel(avatar);
    this._documents = SimpleDocumentModel.fromArrayResponse(documents || []);
    this.bankAccounts = bankAccounts;
    this._dateOfIncorporation = new Date(dateOfIncorporation);
    this._dateOfBirth = new Date(dateOfBirth);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  set documents(documents) { this._documents = SimpleDocumentModel.fromArrayResponse(documents || []); }

  get documents() { return this._documents; }

  set avatar(avatar) { this._avatar = new LegalEntityAvatarModel(avatar); }

  get avatar() { return this._avatar; }

  set dateOfIncorporation(dateOfIncorporation) { this._dateOfIncorporation = new Date(dateOfIncorporation); }

  get dateOfIncorporation() { return this._dateOfIncorporation; }

  set dateOfBirth(dateOfBirth) { this._dateOfBirth = new Date(dateOfBirth); }

  get dateOfBirth() { return this._dateOfBirth; }

  get accountDetails() {
    return ({
      avatar: this.avatar,
      accountName: this.name,
      status: this.status,
      walletAddress: this.walletAddress,
      walletType: this.walletType,
    });
  }

  get loginAndContact() {
    return ({
      email: this.email,
      phoneNumber: this.phoneNumber,
      address: {
        street: this.street,
        additionalAddress: this.additionalAddress,
        city: this.city,
        country: this.country,
        county: this.county,
        postalCode: this.postalCode,
      },
      dateOfBirth: this.dateOfBirth,
      homepage: this.homepage,
    });
  }

  get legalInfo() {
    return ({
      jurisdiction: this.jurisdiction,
      dateOfIncorporation: this.dateOfIncorporation,
      registeredBy: this.registeredBy,
      type: this.companyType,
    });
  }

  get locale() {
    return ({
      baseCurrency: this.baseCurrency,
      language: this.language,
      timeZone: this.timeZone,
    });
  }
}

export default ProfileModel;
