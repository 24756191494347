import { useQuery } from '@tanstack/react-query';

import { BoardMembershipOverview } from '@trustwise/design-system';
import BackButton from 'core/page/parts/backButton';
import { PageContent } from 'core/page';
import { useNavigate, useParams } from 'react-router-dom';
import { EventType } from 'governanceEvents/types';
import { getGovernanceEventDetailPath } from 'governanceEvents/utils';
import { redirectTo } from 'core/utils';
import { onFileDownload } from 'media/utils';
import {
  getBoardMemberTransfers,
  getBoardMembers,
  getBoardMembersEvents,
  getBoardMembershipDocuments,
} from './actions';
import { useMembershipsList } from './hooks';
import { BASE_API_PATH } from '../constants';

export const MembershipOverview = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  if (!companyId) {
    throw new Error('Url parameter companyId is required');
  }
  const transactionsPath = `/memberships/boardroom/${companyId}/transactions/`;

  const baseDetailPath = `${BASE_API_PATH}${companyId}/`;

  const { data: memberships } = useMembershipsList();
  const { data: transfers } = useQuery({
    queryKey: ['transfers', companyId],
    queryFn: () => getBoardMemberTransfers(baseDetailPath),
  });
  const { data: events } = useQuery({ queryKey: ['events', companyId], queryFn: () => getBoardMembersEvents(companyId) });
  const { data: members } = useQuery({ queryKey: ['members', companyId], queryFn: () => getBoardMembers(baseDetailPath) });
  const { data: documents } = useQuery({
    queryKey: ['documents', companyId],
    queryFn: () => getBoardMembershipDocuments(baseDetailPath),
  });

  const membershipCompany = memberships?.find((membership) => membership.company.id === Number(companyId))?.company;

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Boardroom')}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <BoardMembershipOverview
        company={membershipCompany}
        transfers={transfers}
        events={events}
        members={members}
        documents={documents}
        actions={{
          onEventClick: (id: number, type: EventType) => { navigate(getGovernanceEventDetailPath(id, type, 'meetings/')); },
          onFileDownload: (fileId: number) => onFileDownload(
            fileId,
            documents,
            `/boardroom/memberships/${companyId}/documents/`,
          ),
          onSeeAllDocuments: () => { redirectTo(`/memberships/boardroom/${companyId}/documents/`); },
          onSeeAllEvents: () => { navigate('meetings/'); },
          onSeeAllMembers: () => { navigate('members/'); },
          onSeeAllTransfers: () => { redirectTo(transactionsPath); },
          onTransferClick: (transferId: number) => { redirectTo(`${transactionsPath}${transferId}`); },
          onMemberClick: (memberId: number) => { navigate(`members/${memberId}/`); },
        }}
      />
    </PageContent>
  );
};
