import PropTypes from 'prop-types';
import { IconButton } from 'core/forms/fields/button';
import { IconLink } from 'core/link';


const BackButton = ({ onClick, href, useRouterLink }) => (
  href
    ? <IconLink className="back-button" to={href} useRouterLink={useRouterLink} />
    : <IconButton className="back-button" onClick={onClick} />
);

BackButton.defaultProps = {
  onClick: () => {},
  href: '',
  useRouterLink: false,
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  useRouterLink: PropTypes.bool,
};

export default BackButton;
