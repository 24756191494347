export const LegalEntityTransactions = {
  legal_entity_verified: gettext('Verify Legal Entity'),
  company_creation: gettext('Create Company'),
  company_multisig_creation: gettext('Create Company Multi Sig'),
  personal_account_contract_creation: gettext('Create Personal Account'),
  full_delegate_contract_creation: gettext('Full Delegate Contract Creation'),
} as const;

export const GovernanceEventsTransactions = {
  meeting_send_invitation_and_create_contract: gettext('Send meeting invitation'),
  resolution_open_and_create_contract: gettext('Open resolution'),
  resolution_send_results: gettext('Send Resolution Results'),
  meeting_open: gettext('Open Meeting'),
  meeting_vote: gettext('Meeting Vote'),
  meeting_open_proposal: gettext('Meeting Open Proposal'),
  meeting_close_proposal: gettext('Meeting Close Proposal'),
  meeting_update_proposals: gettext('Meeting Update Proposals'),
  meeting_cancel: gettext('Meeting Cancel'),
  meeting_close: gettext('Meeting Close'),
  meeting_request_minutes_signing: gettext('Meeting Request Minutes Signing'),
  meeting_send_minutes: gettext('Meeting Send Minutes'),
  meeting_proxy_instructions: gettext('Meeting Proxy Instructions'),
  meeting_cancel_proxy_votes: gettext('Meeting Cancel Proxy Votes'),
  meeting_release_proxy_votes: gettext('Meeting Release Proxy Votes'),
  meeting_contract_creation: gettext('Send Meeting Invitation'),
  governance_event_distribute_tokens: gettext('Distribute Voting Tokens'),
  voting_create_and_open_proposal: gettext('Create And Open Voting Proposal'),
  voting_execute_proposal: gettext('Count Votes on Proposal'),
  resolution_contract_creation: gettext('Send Resolution Invitation'),
  resolution_vote: gettext('Resolution Vote'),
  governance_event_update_status: gettext('Update Governance Event Status'),
  voting_change_shares_address: gettext('Change Voting Shares Address'),
  voting_check_in: gettext('Voting Check In'),
  voting_close_proposal: gettext('Close Voting Proposal'),
  governance_event_freeze_tokens: gettext('Freeze Governance Event Tokens'),
} as const;

export const RegisteredObjectsTransactions = {
  registered_object_create: gettext('Create Registered Object'),
  registered_object_update: gettext('Update Registered Object'),
  registered_object_manager_update: gettext('Update Registered Object Manager'),
  registered_object_event_create: gettext('Create Registered Object Event'),
  registered_object_location_add: gettext('Add Registered Object Location'),
  accept_terms_and_conditions: gettext('Accept Terms And Conditions'),
} as const;

export const PropertyTokenTransactions = {
  property_token_transfer_initialize: gettext('Initialize Property Token Transfer'),
  property_token_transfer_accept: gettext('Accept Property Token Transfer'),
  property_token_transfer_payment_confirmation: gettext('Confirm Payment for Property Token Transfer'),
  property_token_transfer_complete: gettext('Complete Property Token Transfer'),
  property_token_transfer_cancel: gettext('Cancel Property Token Transfer'),
} as const;

export const DocumentsTransactions = {
  document_upload: gettext('Upload Document'),
  document_signer_create: gettext('Invite Document Signatories'),
  document_signer_sign: gettext('Sign Document'),
} as const;

export const EntityMessagesTransactions = {
  entity_message_sent: gettext('Send Entity Message'),
  entity_message_confirmation_init: gettext('Request Message Confirmation'),
  entity_message_confirmation_sign: gettext('Sign Message Confirmation'),
} as const;

export const DelegatesTransactions = {
  full_delegate_contract_add_delegate: gettext('Add Delegate'),
  full_delegate_change_delegate_weight: gettext('Change Delegate Signature Weight'),
  full_delegate_remove_delegate: gettext('Delegate Remove'),
  delegate_transaction_revoke: gettext('Revoke Delegation Multisig Signature'),
  delegate_transaction_confirm: gettext('Confirm Delegation Multisig Signature'),
} as const;

export const BoardMembersTransactions = {
  board_members_contract_creation: gettext('Create Board Members Contract'),
  board_member_adding: gettext('Add Board Member'),
  board_member_removal: gettext('Remove Board Member'),
  board_member_change_role_or_casting_vote: gettext('Change Board Member Role Or Casting Vote'),
} as const;

export const CompanySignatoriesTransactions = {
  add_company_secretary: gettext('Add Company Secretary'),
  editing_company_secretary: gettext('Edit Company Secretary'),
  remove_company_secretary: gettext('Remove Company Secretary'),
  company_transaction_revoke: gettext('Revoke Delegation Multisig Signature'),
  company_transaction_confirm: gettext('Confirm Delegation Multisig Signature'),
} as const;

export const CompanyMembersTransactions = {
  company_members_contract_creation: gettext('Create Company Membership Contract'),
  company_member_adding: gettext('Add Company Member'),
  company_member_removing: gettext('Remove Company Member'),
} as const;

export const CapitalIncreaseTransactions = {
  cap_increase_create_subscription_contract: gettext('Create Subscription Contract'),
  cap_increase_submit_subscription_certificate: gettext('Submit Subscription Certificate'),
  cap_increase_update_power_of_attorney: gettext('Update Power of Attorney'),
  cap_increase_allot_subscription_certificates: gettext('Allot Subscription Certificates'),
  cap_increase_edit_selected_subscribers: gettext('Edit Selected Subscribers'),
  cap_increase_finalize_selected_subscriptions_list: gettext('Finalize Selected Subscriptions List'),
  cap_increase_batch_distribution: gettext('Capital Increase Batch Distribution'),
  cap_increase_cancel_subscription: gettext('Capital Increase Cancel Subscription'),
  cap_increase_confirm_attestation: gettext('Capital Increase Attestation'),
} as const;

export const TransferTransactions = {
  restricted_transfer_creation: gettext('Initialize Restricted Transfer'),
  restricted_transfer_cancellation: gettext('Cancel Restricted Transfer'),
  restricted_transfer_acceptance: gettext('Accept Restricted Transfer'),
  restricted_transfer_bm_vote: gettext('Restricted Transfer Approval by Board Members'),
  restricted_transfer_offchain_confirmation: gettext('Confirm Restricted Transfer Payment'),
  restricted_transfer_completion: gettext('Complete Restricted Transfer'),
  restricted_transfer_bo_update: gettext('Update Beneficial Ownership Report for Restricted Transfer'),
  token_transfer: gettext('Token Transfer'),
  token_burn: gettext('Token Burn'),
  unrestricted_transfer_creation: gettext('Initialize Unrestricted Transfer'),
  unrestricted_transfer_acceptance: gettext('Accept Unrestricted Transfer'),
  unrestricted_transfer_cancellation: gettext('Cancel Unrestricted Transfer'),
  unrestricted_transfer_completion: gettext('Complete Unrestricted Transfer'),
  unrestricted_transfer_offchain_confirmation: gettext('Confirm Unrestricted Transfer Payment'),
  unrestricted_transfer_bo_update: gettext('Update Beneficial Ownership Report for Unrestricted Transfer'),
} as const;

export const TokenDistributionTransactions = {
  token_distribution: gettext('Token Distribution'),
  token_mintage_with_confirmation_bo_update: gettext('Update Beneficial Ownership Report for Token Distribution Confirmation'),
  token_mintage_bo_report: gettext('Report Beneficial Ownership for Token Distribution'),
  token_mintage_bo_update: gettext('Update Beneficial Ownership Report for Token Distribution'),
  token_mintage_with_confirmation_acceptance: gettext('Accept Token Mintage with Confirmation'),
} as const;

// @todo improve naming and make more specific
export const TokenTransactions = {
  token_contract_upgrade: gettext('Upgrade Token Contract'),
  trustwise_payment_token_creation: gettext('Create Payment Token'),
  payment_tokens_mint: gettext('Increase Payment Token Supply'),
  dlt_register_value_rights_token_creation: gettext('Create Registered Shares (Rvr)'),
  dlt_register_value_rights_token_conversion: gettext('Convert Token'),
  token_contract_kill: gettext('Invalidate Token Contract'),
  token_suspend: gettext('Suspend Token'),
  token_unsuspend: gettext('Unsuspend Token'),
  cbdc_issue_banknotes: gettext('Issue Banknotes'),
  token_generate_tokens_to_mint: gettext('Token Capital Increase'),
  create_co_ownership_quotas: gettext('Create Co-Ownership Quotas'),
  payment_token_request: gettext('Transfer Payment Token'),
  registered_shares_ch_creation: gettext('Create Registered Shares Ch'),
} as const;

export const CertificateTransactions = {
  certificate_contract_creation: gettext('Create Certificate'),
  certificate_add_issuer_registry: gettext('Add Issuer Registry to Certificate'),
  issuer_registry_approve_issuer: gettext('Approve Issuer'),
  issuer_registry_contract_creation: gettext('Create Issuer Registry'),
  certificate_issuing: gettext('Issue Certificate'),
} as const;

export const ConsortiumTransactions = {
  company_members_consortium_contract_assign: gettext('Assign Consortium as Company Membership Contract'),
  peer_allowed: gettext('Peer Allowed'),
  peer_disallowed: gettext('Peer Disallowed'),
  voted_to_add_validator: gettext('Vote to Add Validator'),
  voted_to_remove_validator: gettext('Vote to Remove Validator'),
  voted_to_add_consortium_member: gettext('Vote to Add Consortium Member'),
  voted_to_remove_consortium_member: gettext('Vote to Remove Consortium Member'),
  consortium_member_leave: gettext('Consortium Member Leave'),
  consortium_member_invitation_created: gettext('Create Consortium Member Invitation'),
  consortium_member_application_submitted: gettext('Submit Consortium Member Application'),
  consortium_member_accept_application: gettext('Accept Consortium Member Application'),
  consortium_member_confirm_transaction: gettext('Approve Application'),
  consortium_member_revoke_transaction: gettext('Revoke Application'),
  consortium_enable_peer_manager: gettext('Enable Peer Manager on Consortium'),
  validator_invitation_created: gettext('Create Validator Invitation'),
  peer_enode_added: gettext('Add Peer Node'),
  validator_application_submitted: gettext('Submit Validator Application'),
  consortium_add_admin: gettext('Add Peer Admin'),
  enable_full_tx_admin_permissions: gettext('Enable Full Transaction Permissions for Admin'),
  disable_full_tx_admin_permissions: gettext('Disable Full Transaction Permissions for Admin'),
  consortium_member_approve_validator_invitation: gettext('Approve Validator Invitation'),
  consortium_member_reject_validator_invitation: gettext('Rejected Validator Invitation'),
  peer_owner_invitation_created: gettext('Create Node Owner Invitation'),
  consortium_member_cancel_invitation: gettext('Cancel Invitation for Consortium Membership'),
  consortium_member_removal_approval: gettext('Approve Consortium Member Removal'),
  consortium_member_removal_rejection: gettext('Reject Consortium Member Removal'),
  peer_owner_removal_approval: gettext('Approve Peer Owner Removal'),
  peer_owner_removal_rejection: gettext('Reject Peer Owner Removal'),
  consortium_member_validator_invitation_cancellation: gettext('Cancel Validator Invitation'),
  consortium_add_tx_permission_admin_submission: gettext('consortium add tx permission admin submission'),
  consortium_allow_action_confirmation: gettext('consortium allow action confirmation'),
  consortium_member_invitation_submission: gettext('consortium member invitation submission'),
  peer_owner_addition_submission: gettext('peer owner addition submission'),
  peers_upgrade_confirmation: gettext('peers upgrade confirmation'),
  peers_upgrade_submission: gettext('peers upgrade submission'),
  txp_upgrade_submission: gettext('txp upgrade submission'),
  val_upgrade_submission: gettext('val upgrade submission'),
  validator_application_voting_submission: gettext('validator application voting submission'),
  validator_removal_confirmation: gettext('validator removal confirmation'),
} as const;

export const MarketsTransactions = {
  limit_order_sell_create: gettext('Create Limit Sell Order'),
  limit_order_sell_accept: gettext('Accept Limit Sell Order'),
  limit_order_sell_cancel: gettext('Cancel Limit Sell Order'),
} as const;

export const CustodialTransactions = {
  custodial_personal_contract_create: gettext('Custodial personal contract create'),
  custodial_company_contract_create: gettext('Custodial company contract create'),
} as const;

export const GeneralUpgradeTransactions = {
  contract_upgrade: gettext('contract upgrade'),
} as const;

export const RegistriesTransactions = {
  permissions_registry_member_add: gettext('Permissions Registry Member add'),
  permissions_registry_member_update: gettext('Permissions Registry Member update'),
  permissions_registry_member_delete: gettext('Permissions Registry Member delete'),
} as const;

export const UtilityTokenTransactions = {
  utility_token_creation: gettext('Create utility token'),
  utility_token_mint: gettext('Mint utility tokens'),
  utility_token_burn: gettext('Burn utility tokens'),
  utility_token_transfer: gettext('Transfer utility tokens'),
} as const;

export const MultisigTransactionsChoices = {
  ...LegalEntityTransactions,
  ...GovernanceEventsTransactions,
  ...RegisteredObjectsTransactions,
  ...PropertyTokenTransactions,
  ...DocumentsTransactions,
  ...EntityMessagesTransactions,
  ...DelegatesTransactions,
  ...BoardMembersTransactions,
  ...CompanySignatoriesTransactions,
  ...CompanyMembersTransactions,
  ...CapitalIncreaseTransactions,
  ...TransferTransactions,
  ...TokenDistributionTransactions,
  ...TokenTransactions,
  ...CertificateTransactions,
  ...ConsortiumTransactions,
  ...MarketsTransactions,
  ...CustodialTransactions,
  ...GeneralUpgradeTransactions,
  ...RegistriesTransactions,
  ...UtilityTokenTransactions,
} as const;

export const MultisigTransactionTypeGroups = {
  accounts: Object.keys(LegalEntityTransactions),
  governance_events: Object.keys(GovernanceEventsTransactions),
  registered_objects: Object.keys(RegisteredObjectsTransactions),
  property_token: Object.keys(PropertyTokenTransactions),
  documents: Object.keys(DocumentsTransactions),
  entity_messages: Object.keys(EntityMessagesTransactions),
  delegates: Object.keys(DelegatesTransactions),
  board_members: Object.keys(BoardMembersTransactions),
  company_signatories: Object.keys(CompanySignatoriesTransactions),
  company_members: Object.keys(CompanyMembersTransactions),
  capital_increase: Object.keys(CapitalIncreaseTransactions),
  transfers: Object.keys(TransferTransactions),
  distributions: Object.keys(TokenDistributionTransactions),
  // @todo improve naming and make more specific
  tokens: Object.keys(TokenTransactions),
  certificates: Object.keys(CertificateTransactions),
  consortium: Object.keys(ConsortiumTransactions),
  markets: Object.keys(MarketsTransactions),
  custodians: Object.keys(CustodialTransactions),
  general_upgrade: Object.keys(GeneralUpgradeTransactions),
  registries: Object.keys(RegistriesTransactions),
  utility_tokens: Object.keys(UtilityTokenTransactions),
} as const;

export const CompanySignatorySignatureTypes = {
  COLLECTIVE: 'collective',
  SINGLE: 'single',
} as const;
