import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UtilityTokensListView as UtilityTokensList } from '@trustwise/design-system';
import { getUrl, getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import { UtilityTokenIssuerModel } from 'liabilities/utilityTokens/model';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import convertResponseToModel from 'utils/responseToModel';

const UtilityTokensListView = () => {
  const [tokens, setTokens] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(getApiUrl('/utility-tokens/'))
      .then(({ data }) => {
        setTokens(convertResponseToModel(data, UtilityTokenIssuerModel));
      })
      .catch(console.error);
  }, []);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Utility')}
      headerLeft={<BackButton href={getUrl('/')} />}
    >
      <UtilityTokensList
        tokens={tokens}
        onTokenClick={(id) => navigate(`${id}/`)}
        onTokenIssue={() => navigate('create/')}
      />
    </PageContent>
  );
};

export default UtilityTokensListView;
