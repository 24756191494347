// Pricing const
const NONE = '';
const MONTHLY = 'M';
const QUARTERLY = 'Q';
const YEARLY = 'Y';

const CHARGING_PERIODS = {
  [NONE]: '',
  [MONTHLY]: 'monthly',
  [QUARTERLY]: 'quarterly',
  [YEARLY]: 'yearly',
};

const QUOTATION_PERIODS = {
  [NONE]: gettext('one-off'),
  [MONTHLY]: gettext('monthly'),
  [QUARTERLY]: gettext('quarterly'),
  [YEARLY]: gettext('yearly'),
};

const QUOTATION_PERIODS_DISPLAY = {
  [NONE]: gettext('one-off'),
  [MONTHLY]: gettext('month'),
  [QUARTERLY]: gettext('quarter'),
  [YEARLY]: gettext('year'),
};

const PER_ANNUM = 'PA';
const PER_EVENT = 'PE';
const PER_UNIQUE_VOTE = 'PU';
const PER_ALLOTMENT = 'PL';
const PER_TRANSFER = 'PT';
const PER_REGISTRATION = 'PR';
const PER_SIGNATORY = 'PS';
const PER_SUBSCRIPTION_CERTIFICATE = 'PSC';
const PER_PARTICIPATING_SHAREHOLDER_PER_EVENT = 'PPE';
const PER_CONVERTED_TOKEN_POSITION = 'PCTP';
const PER_STAKEHOLDER = 'PST';
const PER_COMPANY = 'PC';

const QUOTATION_TYPE_DISPLAY = {
  [PER_ANNUM]: gettext('p.a.'),
  [PER_EVENT]: gettext('per event'),
  [PER_UNIQUE_VOTE]: gettext('per unique vote'),
  [PER_ALLOTMENT]: gettext('per allotment'),
  [PER_TRANSFER]: gettext('per transfer'),
  [PER_REGISTRATION]: gettext('per registration'),
  [PER_SIGNATORY]: gettext('per signatory'),
  [PER_SUBSCRIPTION_CERTIFICATE]: gettext('per subscription certificate'),
  [PER_PARTICIPATING_SHAREHOLDER_PER_EVENT]: gettext('per participant per event'),
  [PER_CONVERTED_TOKEN_POSITION]: gettext('per converted token position'),
  [PER_STAKEHOLDER]: gettext('stakeholder'),
  [PER_COMPANY]: gettext('company'),
};

const QUOTATION_TYPE_PLURAL_DISPLAY = {
  [PER_EVENT]: gettext('events'),
  [PER_UNIQUE_VOTE]: gettext('unique votes'),
  [PER_ALLOTMENT]: gettext('allotments'),
  [PER_TRANSFER]: gettext('transfers'),
  [PER_SIGNATORY]: gettext('signatories'),
  [PER_SUBSCRIPTION_CERTIFICATE]: gettext('subscription certificates'),
  [PER_CONVERTED_TOKEN_POSITION]: gettext('converted token positions'),
  [PER_STAKEHOLDER]: gettext('stakeholders'),
  [PER_COMPANY]: gettext('companies'),
};

const FIXED = 'FI';
const USAGE_FIXED = 'UF';
const USAGE_SLABS = 'US';

const PRICE_TYPES = {
  [FIXED]: gettext('Fixed'),
  [USAGE_FIXED]: gettext('Usage Based (Fixed)'),
  [USAGE_SLABS]: gettext('Usage Based (Slabs)'),
};

const INITIAL_TWC_AMOUNT = 25;
const PRICING_CURRENCY = 'CHF';

const APP_CONTACT_LINK = 'https://twex.swiss/en/contact.html';
const APP_PRICING_LINK = 'https://twex.swiss/en/pricing.html';

export {
  QUOTATION_PERIODS,
  QUOTATION_PERIODS_DISPLAY,
  QUOTATION_TYPE_DISPLAY,
  INITIAL_TWC_AMOUNT,
  APP_CONTACT_LINK,
  APP_PRICING_LINK,
  PRICING_CURRENCY,
  PRICE_TYPES,
  USAGE_SLABS,
  QUOTATION_TYPE_PLURAL_DISPLAY,
  CHARGING_PERIODS,
};
