/* eslint-disable react/no-danger */

import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { InlineNotification } from '@trustwise/design-system';
import { formatNumber } from 'core/utils';
import { convertBooleanString } from 'utils/convertBooleanString';
import { icons } from '../icons';

const TransactionPriceInfo = ({ price, isReserved, reservationContext }) => {
  const formattedPrice = formatNumber(parseFloat(price));
  const infoMessage = gettext(
    'In the application all fees and deposits are recorded as <strong>CREDITS</strong>, '
    + 'where <strong>1 CREDIT</strong> equals 1 CHF excluding VAT.',
  );
  let message = gettext(
    'By confirming the transaction your credit balance will be charged with '
    + `<strong>${formattedPrice} CREDITS</strong>`,
  );
  if (convertBooleanString(isReserved)) {
    message = gettext(
      `By confirming the transaction <strong>${formattedPrice} CREDITS</strong> will be blocked on your credit balance.`
      + `${reservationContext}`,
    );
  }
  return (
    <InlineNotification
      icon={icons.transaction}
      message={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />}
      infoMessage={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(infoMessage) }} />}
    />
  );
};

TransactionPriceInfo.defaultProps = {
  price: '',
  isReserved: false,
  reservationContext: gettext('The final amount will be calculated based on the total usage.'),
};

TransactionPriceInfo.propTypes = {
  price: PropTypes.string,
  isReserved: PropTypes.string,
  reservationContext: PropTypes.string,
};

export default TransactionPriceInfo;
