import { convertKeysToCamelCase } from 'core/utils';
import { getTimeFromDate } from 'utils/date';
import { getGlobalContext } from 'core/globals';
import { SimpleLegalEntityModel, LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { SimpleDocumentModel } from 'media/models/document';
import { Model } from 'core/model';
import { getGroupByStatus } from '../utils';

const {
  activeEntity: { id: activeEntityId },
  custodian: { active: custodianActive },
} = getGlobalContext();

/**
 * Governance event list item model
 * Props under restProps are:
 * id, subject
 */
export class GovernanceEventListItemModel extends Model {
  constructor({
    dateTime,
    owner,
    type,
    group,
    status,
    ...restProps
  }) {
    super(restProps);
    this.eventType = `${group}_${type}`;
    this.date = new Date(dateTime);
    this.owner = {
      name: owner.name,
      avatar: {
        backgroundColor: owner.avatar.color,
        ...owner.avatar,
      },
    };
    this.status = status;
    this.groupStatus = getGroupByStatus(status);
  }
}

export class GovernanceEventInviteeModel {
  constructor({
    id,
    name,
    avatar,
    voteWeight,
    indicatedParticipation,
    checkedIn,
    votedByProxy,
    roles,
    castingVote,
  }) {
    this.id = id;
    this.name = name;
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
    // @todo rename here and in the design system component to voteWeight
    this.noOfShares = voteWeight;
    this.acceptedInvitation = indicatedParticipation;
    this.checkedIn = checkedIn;
    this.votedByProxy = votedByProxy;
    this.roles = roles;
    this.castingVote = castingVote;
  }
}

export class GovernanceEventDetailModel {
  constructor({
    id, owner, group, type, subject, dateTime, timeZone, status, statuses, location,
    locationCustom, locationType, languages, videoUrl, noOfInvitees, noOfShareClasses,
    proxyVotingEnabled, proxy, chairman, secretary, documents, shareClasses = [], invitees = [],
    checkedIn, indicatedParticipation, introTextSeen, voterProxyStatus, proxyVotesReleased,
    isVotingParticipant, minutesSignatories, invitationIntro, invitationOutro,
    minutesIntro, minutesOutro, hasCustodialInvitees,
  }) {
    this.id = id;
    this.eventType = `${group}_${type}`;
    this.group = group;
    this._dateTime = new Date(dateTime);
    this._time = getTimeFromDate(new Date(dateTime), timeZone);
    this.timeZone = timeZone;
    this.subject = subject;
    this.owner = new SimpleLegalEntityModel(owner);
    this.status = status;
    this.statuses = statuses;
    this.location = location;
    this.locationCustom = locationCustom;
    this.locationType = locationType;
    this.languages = languages;
    this.videoUrl = videoUrl;
    this.noOfInvitees = noOfInvitees;
    this.noOfShareClasses = noOfShareClasses;
    this.proxyVotingEnabled = proxyVotingEnabled;
    this.hasCustodialInvitees = hasCustodialInvitees;
    this._proxy = proxy ? new SimpleLegalEntityModel(convertKeysToCamelCase(proxy)) : {};
    this._chairman = chairman ? new SimpleLegalEntityModel(convertKeysToCamelCase(chairman)) : {};
    this._secretary = secretary ? new SimpleLegalEntityModel(convertKeysToCamelCase(secretary)) : {};
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
    this._shareClasses = shareClasses.map((item) => convertKeysToCamelCase(item));
    this._invitees = invitees.map((invitee) => (new GovernanceEventInviteeModel(
      convertKeysToCamelCase(invitee),
    )));
    this.checkedIn = checkedIn;
    this.indicatedParticipation = indicatedParticipation;
    this.introTextSeen = introTextSeen;
    this.voterProxyStatus = voterProxyStatus;
    this.proxyVotesReleased = proxyVotesReleased;
    this.isVotingParticipant = isVotingParticipant;
    this.minutesSignatories = minutesSignatories ? convertKeysToCamelCase(minutesSignatories) : undefined;
    this.introOutro = {
      invitation: {
        introText: invitationIntro,
        outroText: invitationOutro,
      },
      minutes: {
        introText: minutesIntro,
        outroText: minutesOutro,
      },
    };
  }

  // Datetime and time setters and getters
  get dateTime() { return this._dateTime; }

  set dateTime(dateTime) { this._dateTime = new Date(dateTime); }

  get time() { return this._time; }

  set time(param) {
    const { dateTime, timeZone } = param;
    this._time = getTimeFromDate(new Date(dateTime), timeZone);
  }

  // Roles setters and getters
  get proxy() { return this._proxy; }

  set proxy(proxy) {
    this._proxy = proxy ? new SimpleLegalEntityModel(convertKeysToCamelCase(proxy)) : {};
  }

  get chairman() { return this._chairman; }

  set chairman(chairman) {
    this._chairman = chairman ? new SimpleLegalEntityModel(convertKeysToCamelCase(chairman)) : {};
  }

  get secretary() { return this._secretary; }

  set secretary(secretary) {
    this._secretary = secretary ? new SimpleLegalEntityModel(convertKeysToCamelCase(secretary)) : {};
  }

  get shareClasses() { return this._shareClasses; }

  set shareClasses(shareClasses) {
    this._shareClasses = shareClasses.map((item) => convertKeysToCamelCase(item));
  }

  get invitees() { return this._invitees; }

  set invitees(invitees) {
    this._invitees = invitees.map((invitee) => (new GovernanceEventInviteeModel(
      convertKeysToCamelCase(invitee),
    )));
  }

  get isOwner() {
    return activeEntityId === this.owner.id;
  }

  get isEditable() {
    return this.isOwner && this.status === 'in_preparation';
  }

  get user() {
    return {
      id: activeEntityId,
      isCustodial: custodianActive,
      isOwner: this.isOwner,
      indicatedParticipation: this.indicatedParticipation,
      checkedIn: this.checkedIn,
      introTextSeen: this.introTextSeen,
      isSecretary: activeEntityId === this.secretary.id,
      isChairman: activeEntityId === this.chairman.id,
      isProxy: activeEntityId === this.proxy.id,
      isVotingUser: this.isVotingParticipant,
      proxyVotingStatus: this.voterProxyStatus,
    };
  }

  get eventDetails() {
    return {
      ...this,
      includedShareClasses: `${this.noOfShareClasses.included}/${this.noOfShareClasses.total}`,
      companyAvatar: this.owner.avatar,
      isEditable: this.isEditable,
      dateTime: this.dateTime,
    };
  }
}
