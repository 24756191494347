import { CHARGING_PERIODS } from 'pricing/const';

/**
 * Base Product Offering Model
 *
 * Props under restProps are:
 * 'id', 'name', 'isPercentageBased', 'quotationDisplay', 'group',
 * 'isRecurring', 'isHighlighted', 'allowedAmount'
 */
export default class PricingPlanItemModel {
  constructor({ price, minPrice, chargingPeriod, ...restProps }) {
    this.price = price ? Number(price) : null;
    this.minPrice = minPrice ? Number(minPrice) : null;
    this.chargingPeriod = CHARGING_PERIODS[chargingPeriod];
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}
