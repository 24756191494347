import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { snakeCase } from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { SOCKETS_URL, RELAY_URL, WS_CONFIG } from 'services/constants';

import axios from 'core/axios';
import { dateToday } from 'utils/date';
import {
  CheckboxField,
  TextField,
  ReactSelectField,
  DateField,
  PasswordField,
  PhoneNumberInput,
  SubmitButton,
  EmailField,
} from 'core/forms/fields';
import FormRouter from 'core/forms/formRouter';
import { parseErrorResponse } from 'core/forms/utils';
import { convertKeysCase } from 'core/utils';
import {
  androidAppInterface,
  iOsAppInterface,
  isAndroidNewWebview,
  isIosNewWebview,
  isNewNativeAppWebview,
} from 'utils/general';
import UserAgreement from 'registration/userAgreement';
import PricingInfo from '../pricingInfo';


const getQRSignUpString = (token) => (
  `tw-signer://sign-up/?version=1&token=${token}&url=${window.location.protocol}//${window.location.host}/api/v1/`
);

const QRStep = ({ setHdWallets, setMasterAddress, hdWallets }) => {

  const [wsToken, setWsToken] = useState('');
  useEffect(() => {
    const clientUrl = `${SOCKETS_URL}${RELAY_URL}/`;
    const ws = new ReconnectingWebSocket(clientUrl, [], WS_CONFIG);
    ws.onerror = (error) => {
      console.error('[WS RELAY] Error', error);
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'relay.token':
          setWsToken(data.payload);
          break;
        case 'relay.hd_addresses':
          setHdWallets(
            Object.keys(data.payload.hd_addresses).map(
              (key) => Object(
                { index: parseInt(key.substr(key.lastIndexOf('/') + 1), 10), address: data.payload.hd_addresses[key] },
              ),
            ),
          );
          setMasterAddress(data.payload.master_address);
          break;
        default:
          console.error('Get unexpected WS message', e.data);
      }
    };
    return () => {
      ws.close();
    };
  }, [setHdWallets, setMasterAddress]);

  return (
    <>
      <h1 className="align-text-center">{gettext('Scan QR')}</h1>
      {(hdWallets.length > 0) && (
        <p className="align-text-center">
          {gettext('Data have been synchronised with Signer app successfully')}
        </p>
      )}
      {(wsToken && hdWallets.length === 0) && (
        <>
          <p className="align-text-center">
            {gettext('Please open Signer app and scan this QR code')}
          </p>
          <div className="row-flex align-text-center">
            <QRCode value={getQRSignUpString(wsToken)} className="qr-code-img large" level="L" />
          </div>
        </>
      )}
      {(!wsToken) && (
        <p className="align-text-center">
          {gettext('Please wait or retry the sign up process again')}
        </p>
      )}
    </>
  );
};

QRStep.propTypes = {
  setMasterAddress: PropTypes.func.isRequired,
  setHdWallets: PropTypes.func.isRequired,
  hdWallets: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const AppSyncStep = ({ setHdWallets, setMasterAddress, hdWallets }) => {
  const [nativeViewShouldOpen, setNativeViewShouldOpen] = useState(false);

  useEffect(() => {
    if (!window.__twexSetHdWallets) {
      window.__twexSetHdWallets = (response) => {
        setHdWallets(Object.keys(response.hd_addresses).map(
          (key) => Object(
            { index: parseInt(key.substr(key.lastIndexOf('/') + 1), 10), address: response.hd_addresses[key] },
          ),
        ));
        setMasterAddress(response.master_address);
      };
    }
    if (hdWallets.length === 0) {
      setNativeViewShouldOpen(true);
      // @todo introduce wrapper to unify ios/android interfaces
      if (isIosNewWebview) {
        iOsAppInterface.onWalletsRequest.postMessage({})
          .then(() => {}, console.error)
          .catch(console.error);
      } else if (isAndroidNewWebview) {
        androidAppInterface.onWalletsRequest();
      }
    }
  }, [setHdWallets, setMasterAddress, hdWallets]);

  return (
    <>
      <h1 className="align-text-center">{gettext('App Sync')}</h1>
      <p className="align-text-center">
        {(hdWallets.length > 0) && (
          gettext('Data have been synchronised with TWEX® app successfully')
        )}
        {(hdWallets.length === 0) && (
          nativeViewShouldOpen ? gettext('Waiting for the data from app') : gettext('Waiting for response')
        )}
      </p>
    </>
  );
};

AppSyncStep.propTypes = {
  setMasterAddress: PropTypes.func.isRequired,
  setHdWallets: PropTypes.func.isRequired,
  hdWallets: PropTypes.arrayOf(PropTypes.string).isRequired,
};

/**
 * Registration Form
 *
 * @todo increase .limited-width width
 * @todo use limited width instead of hardcoded width
 * @todo update agreements checkboxes copy
 *
 * @todo use https://github.com/trustwise/validation
 * @todo debug why component renders multiple times on load (or at least seems so)
 */
const PersonalRegistrationForm = () => {

  const registrationUrl = '/api/v1/registration/';

  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    const url = `${registrationUrl}countries/`;
    axios.get(url)
      .then(({ data }) => { setCountryOptions(data); })
      .catch(console.error);
  }, []);

  const [phoneCountryCode, setPhoneCountryCode] = useState('ch');

  const [hdWallets, setHdWallets] = useState([]);
  const [masterAddress, setMasterAddress] = useState('');

  const validateEmail = (email, setFieldError) => (
    axios.post(`${registrationUrl}validation/`, { email })
      .then(
        () => { },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, setFieldError); },
      )
      .catch(console.error)
  );

  const handleSubmit = (values, actions) => {
    const data = convertKeysCase({
      ...values,
      phoneNumber: `+${values.phoneNumber}`,
      walletType: 'self-managed',
      masterAddress,
      hdWallets,
    }, snakeCase);
    axios.post(registrationUrl, data)
      .then(
        (response) => {
          // @todo introduce wrapper to unify ios/android interfaces
          if (isIosNewWebview) {
            iOsAppInterface.onSignUp.postMessage({ key: response.data.key })
              .then(() => { window.location.href = '/'; }, console.error)
              .catch(console.error);
          } else if (isAndroidNewWebview) {
            androidAppInterface.onSignUp(JSON.stringify({ key: response.data.key }));
            window.location.href = '/';
          } else {
            window.location.href = '/';
          }
        },
        ({ response: { data: errors } }) => {
          actions.setSubmitting(false);
          parseErrorResponse(errors, actions.setFieldError);
        },
      )
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        givenName: '',
        surname: '',
        email: '',
        password: '',
        dateOfBirth: '',
        countryCode: 'CH',
        street: '',
        additionalAddress: '',
        city: '',
        postalCode: '',
        phoneNumber: '',
        userAgreementConfirmation: false,
        userWarrantiesConfirmation: false,
        // @todo avoid the need to set nonFieldErrors here, consider formik status:
        // https://jaredpalmer.com/formik/docs/api/formik#setstatus-status-any-void
        nonFieldErrors: '',

      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        givenName: Yup.string().required(gettext('This field is required')),
        surname: Yup.string().required(gettext('This field is required')),
        email: Yup.string().email().required(gettext('This field is required')),
        password: Yup.string().required(gettext('This field is required')),
        dateOfBirth: Yup.date()
          .max(dateToday(), gettext('Date cannot be in the future.'))
          .required(gettext('Please select a valid date.')),
        countryCode: Yup.string().required(gettext('This field is required')),
        city: Yup.string().required(gettext('This field is required')),
        street: Yup.string().required(gettext('This field is required')),
        additionalAddress: Yup.string(),
        postalCode: Yup.string(),
        phoneNumber:
          Yup.string()
            .required(gettext('This field is required'))
            .phone(phoneCountryCode.toUpperCase(), gettext('Phone number format not recognized')),
        userAgreementConfirmation: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed')),
        userWarrantiesConfirmation: Yup.bool().oneOf([true], gettext('Your confirmation is necessary to proceed')),
      })}
    >
      {({ isValid, dirty, errors, values, isSubmitting, setFieldError, setTouched }) => {
        const onStepSubmit = (step, navigate, to) => {
          const touchedFields = {};
          step.fieldNames.forEach((field) => { touchedFields[field] = true; });
          setTouched(touchedFields).then((err) => {
            if (!Object.keys(err).filter((fieldName) => step.fieldNames.includes(fieldName)).length) {
              if (step.fieldNames.includes('email')) {
                validateEmail(values.email, setFieldError).then(() => {
                  navigate(to);
                });
              } else { navigate(to); }
            }
          });
        };

        const steps = [
          {
            pathName: 'pricing-info',
            onStepSubmit: (_step, navigate, to) => { navigate(to); },
            fieldNames: [],
            component: <PricingInfo />,
          },
          {
            pathName: 'personal',
            onStepSubmit,
            component: (
              <>
                <TextField label={gettext('First Name')} name="givenName" />
                <TextField label={gettext('Last Name')} name="surname" />
                <DateField label={gettext('Date of Birth')} name="dateOfBirth" />
                <EmailField label={gettext('E-mail')} name="email" autoComplete="username" />
                <PasswordField name="password" autoComplete="new-password" />
              </>
            ),
          },
          {
            pathName: 'contact',
            onStepSubmit,
            component: (
              <>
                <TextField
                  label={gettext('Address Line 1')}
                  name="street"
                  placeholder={gettext('Street and number, P.O. box, c/o')}
                />
                <TextField
                  label={gettext('Address Line 2')}
                  name="additionalAddress"
                  placeholder={gettext('Apartment, unit, building, floor')}
                  isOptional
                />
                <TextField label={gettext('Postal Code')} name="postalCode" />
                <TextField label={gettext('City')} name="city" />
                <ReactSelectField
                  label={gettext('Country')}
                  name="countryCode"
                  options={countryOptions}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.label}
                  isSearchable
                />
                <PhoneNumberInput
                  label={gettext('Phone Number')}
                  name="phoneNumber"
                  country="ch"
                  preferredCountries={['ch', 'de']}
                  setPhoneCountryCode={setPhoneCountryCode}
                />
              </>
            ),
          },
          {
            pathName: 'agreement',
            onStepSubmit,
            component: (
              <>
                <UserAgreement />
                <CheckboxField
                  label={gettext('I confirm that I have read and understood the General User Agreement')}
                  name="userAgreementConfirmation"
                />
              </>
            ),
          },
          {
            pathName: 'qr',
            onStepSubmit,
            fieldNames: [],
            disabled: !masterAddress || !hdWallets.length,
            component: isNewNativeAppWebview ? (
              <AppSyncStep setHdWallets={setHdWallets} setMasterAddress={setMasterAddress} hdWallets={hdWallets} />
            ) : (
              <QRStep setHdWallets={setHdWallets} setMasterAddress={setMasterAddress} hdWallets={hdWallets} />
            ),
          },
          {
            pathName: 'warranties',
            onStepSubmit,
            component: (
              <>
                <div className="scrollable-content-area margin-bottom">
                  <h1>{gettext('Warranties by User')}</h1>
                  <p>
                    {gettext(
                      'USER confirms that he/she has noted down the 12 words of the recovery phrase'
                      + ' in the correct order, has not shared it with anybody and has stored it away'
                      + ' at one or multiple safe places where it is accessible without any electronic device.',
                    )}
                  </p>
                  <p>
                    {gettext('USER confirms that he/she is obliged to keep a physical copy of the identities provided in Twex.')}
                  </p>
                </div>
                <CheckboxField
                  label={gettext('I confirm')}
                  name="userWarrantiesConfirmation"
                />
              </>
            ),
          },
        ];
        const isDisabled = !masterAddress || !hdWallets.length || !isValid || isSubmitting;
        return (
          <Form className="limited-width">
            <FormRouter
              steps={steps}
              basePath="/signup/"
              formProps={{ dirty, errors }}
              submitButton={(
                <SubmitButton disabled={isDisabled} className="top-margin">
                  {gettext('Sign Up')}
                </SubmitButton>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );

};


export default PersonalRegistrationForm;
