import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { GroupInformationView } from '@trustwise/design-system';

import axios from 'core/axios';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { convertKeysToCamelCase } from 'core/utils';
import { isNativeAppWebview } from 'utils/general';
import { getUrl, getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import EntityMessageModel from '../model';

const EntityMessageDetail = () => {
  const { delegate: { active: delegateActive } } = getGlobalContext();
  const { messageId } = useParams();
  const [message, setMessage] = useState(undefined);
  const { state: routerState } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(getApiUrl(`/messages/${messageId}/`))
      .then(({ data }) => setMessage(new EntityMessageModel(convertKeysToCamelCase(data))))
      .catch(console.error);
  }, [messageId]);

  useEffect(() => {
    !!message && !message.isSeen && axios.post(getApiUrl(`/messages/${messageId}/seen/`))
      .then(() => setMessage(new EntityMessageModel({ ...message, isSeen: true })))
      .catch(console.error);
  }, [messageId, message]);

  const onConfirm = (setDisabled) => {
    axios.post(getApiUrl(`/messages/${messageId}/confirm/`))
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl(`/meetings/informations/${messageId}/`));
        },
      )
      .catch((error) => { console.error(error); })
      .finally(() => { setDisabled(false); });
  };

  const onFileDownload = (fileId) => {
    const file = message.files.find((messageFile) => messageFile.id === fileId);
    window.location = isNativeAppWebview ?
      file.nativeUrl
      : getApiUrl(`/messages/${messageId}/files/${fileId}/download/`);
  };
  return (
    <PageContent
      fullscreen
      pageHeading={message ? message.subject : ''}
      headerLeft={<BackButton onClick={() => { navigate('/', { state: routerState }); }} />}
      pageMessages={delegateActive && message && message.confirmationRequired && [
        <li className="error" key="delegate-confirmation-message">
          {gettext('This event requires a confirmation from recipient')}
        </li>,
      ]}
    >
      {!message ? <GroupInformationView /> : (
        <GroupInformationView
          recipientsLabel={message.recipientsLabel}
          sender={message.sender}
          isOwner={message.isSender}
          content={message.content}
          dateTime={message.dateTime}
          files={message.files}
          onFileDownload={onFileDownload}
          confirmationRequired={message.confirmationRequired}
          isConfirmed={message.isConfirmed}
          onConfirm={!delegateActive ? onConfirm : undefined}
        />
      )}
    </PageContent>
  );
};

export default EntityMessageDetail;
