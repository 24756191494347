import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { convertBooleanString } from 'utils/convertBooleanString';
import DocumentsRoutes from './routes';


const DocumentsNavigation = ({
  relatedObjectModel,
  relatedObjectId,
  isEditable,
  signatoriesEnabled,
  registrationEnabled,
  queryParams,
  isArchive,
  signatoryRoles,
}) => (
  <Router>
    <DocumentsRoutes
      relatedObjectModel={relatedObjectModel}
      relatedObjectId={relatedObjectId}
      isEditable={convertBooleanString(isEditable)}
      signatoriesEnabled={convertBooleanString(signatoriesEnabled)}
      registrationEnabled={convertBooleanString(registrationEnabled)}
      queryParams={queryParams}
      isArchive={convertBooleanString(isArchive)}
      signatoryRoles={typeof signatoryRoles === 'string' ?
        JSON.parse(signatoryRoles.replace(/'/gi, '"'))
        : signatoryRoles}
    />
  </Router>
);
DocumentsNavigation.defaultProps = {
  relatedObjectModel: '',
  relatedObjectId: null,
  queryParams: {},
  isEditable: false,
  signatoriesEnabled: false,
  registrationEnabled: false,
  isArchive: false,
  signatoryRoles: [
    { value: 'witness', label: gettext('Witness') },
    { value: 'signatory', label: gettext('Secretary') },
    { value: 'other', label: gettext('Other') },
  ],
};

DocumentsNavigation.propTypes = {
  relatedObjectModel: PropTypes.string,
  relatedObjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  queryParams: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isEditable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  signatoriesEnabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  registrationEnabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isArchive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  signatoryRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
};

export default DocumentsNavigation;
