const DEFAULT_TIME_ZONE = 'UTC';

const getTimeZonesOptions = () => {
  // @todo discuss how to reasonably extend the list here and in python
  const filteredOptions = Intl.supportedValuesOf('timeZone').filter(
    (timeZone) => ['Nicosia', 'London', 'Zurich', 'Berlin', 'Prague', 'Kiev'].some(
      (item) => timeZone.includes(item),
    ),
  );
  return [
    { value: DEFAULT_TIME_ZONE, label: gettext(`Default (${DEFAULT_TIME_ZONE})`) },
    ...filteredOptions.map((option) => ({ value: option, label: option })),
  ];
};

const ASC_SORTING_ORDER = 'asc';
const DESC_SORTING_ORDER = 'desc';

export {
  DEFAULT_TIME_ZONE,
  getTimeZonesOptions,
  ASC_SORTING_ORDER,
  DESC_SORTING_ORDER,
};
