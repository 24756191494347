import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { MultisigTransactionsListTableView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { MultisigTransactionListItemModel } from 'signatories/models';
import { getTransactions } from './actions';

export const MultisigTransactionsListView = () => {
  const navigate = useNavigate();

  // @todo check how pagination and infinite scroll works in react query
  const { data: transactions } = useQuery<MultisigTransactionListItemModel>({
    queryKey: ['transactions'],
    queryFn: () => getTransactions(['pending', 'executed']),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Transactions Approvals & History')}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <MultisigTransactionsListTableView
        transactions={transactions}
        onTransactionClick={(id: number) => { navigate(`${id}/`); }}
      />
    </PageContent>
  );
};
