import { SimpleDocumentModel } from 'media/models/document';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import PricingPlanModel from './pricingPlan';

/**
 * Base Product Offering Model
 *
 * Props under restProps are: 'id', 'title'
 */
export class BaseProductOfferingModel {
  constructor({ offeringType, ...restProps }) {
    this.type = offeringType;
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Product Offering Model
 *
 * Props under restProps are:
 * 'id', 'title', 'status', 'requirements', 'isSubscribed'
 */
export class ProductOfferingModel {
  constructor({
    createdAt, offeringType, provider, documents, pricingPlans, shortDesc, desc, ...restProps
  }) {
    this.createdAt = new Date(createdAt);
    this.type = offeringType;
    this.documents = SimpleDocumentModel.fromArrayResponse(documents || []);
    this.provider = new SimpleLegalEntityModel(provider);
    this.pricingPlans = PricingPlanModel.fromArrayResponse(pricingPlans || []);
    this.shortDescription = shortDesc;
    this.description = desc;
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  get isApplicationRequired() {
    return !!(this.requirements && this.requirements.length);
  }

  get agreements() {
    return this.documents.map((document) => (
      document.files.map((file) => ({
        id: file.id,
        name: document.subject,
        type: document.type,
      }))
    )).flat();
  }

  get asObject() {
    return {
      ...this,
      agreements: this.agreements,
      isApplicationRequired: this.isApplicationRequired,
    };
  }

  static toArrayAsObject(data) {
    if (!data) {
      return undefined;
    }
    return data.map((item) => item.asObject);
  }
}
