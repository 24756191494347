import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { MultisigTransactionDetailView as MultisigTransactionDetailViewDS } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import { MultisigTransactionDetailModel } from 'signatories/models';
import {
  onFileDownload,
} from 'core/page/transactionSigning/actions';
import { SetDisabled } from 'types';
import BackButton from 'core/page/parts/backButton';
import {
  confirmTransaction as confirm,
  getTransactionDetail,
  getTransactionSigningHistory,
  getUnsignedTransactionDetails,
  revokeTransactionSignature as revoke,
} from './actions';

export const MultisigTransactionDetailView = () => {
  const { multisigTransactionId: pathTransactionId } = useParams();
  if (!pathTransactionId) {
    throw new Error('multisigTransactionId is required');
  }
  const multisigTransactionId = Number(pathTransactionId);

  const { data: transaction } = useQuery<MultisigTransactionDetailModel>({
    queryKey: ['transaction', multisigTransactionId],
    queryFn: () => getTransactionDetail(multisigTransactionId),
  });
  const { data: signingHistory } = useQuery({
    queryKey: ['signingHistory', multisigTransactionId],
    queryFn: () => getTransactionSigningHistory(multisigTransactionId),
  });

  const unsignedHash = transaction?.initUnsignedHash;

  const { data: unsignedTxDetails } = useQuery({
    enabled: !!transaction,
    queryKey: ['unsignedTxDetails', unsignedHash],
    queryFn: () => getUnsignedTransactionDetails(multisigTransactionId),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={transaction?.title || ''}
      headerLeft={<BackButton href="/multisig-transactions/" useRouterLink />}
    >
      <MultisigTransactionDetailViewDS
        {...transaction}
        {...unsignedTxDetails}
        onFileDownload={((fileId: number) => onFileDownload(
          fileId,
          unsignedTxDetails.documents,
          getApiUrl(`/multisig-transactions/${multisigTransactionId}/`),
        ))}
        events={signingHistory}
        onConfirm={(_ev: React.MouseEvent, setDisabled: SetDisabled) => confirm(multisigTransactionId, setDisabled)}
        onRevoke={(_ev: React.MouseEvent, setDisabled: SetDisabled) => revoke(multisigTransactionId, setDisabled)}
      />
    </PageContent>
  );
};
