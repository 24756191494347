import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { getGlobalContext } from 'core/globals';
import { getMultisigTransactionGroup } from 'signatories/views/utils';
import { Model } from 'core/model';
import {
  MultisigSigningHistoryAction,
  MultisigTransactionStatus,
  MultisigTransactionType,
  MultisigTransactionTypeGroup,
} from 'signatories/types';
import { MultisigTransactionsChoices } from 'signatories/consts';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';

const { actingPerson: { id: actingPersonId } } = getGlobalContext();

interface MultisigTransactionListItemModelProps {
  /**
   * Multisig transaction status
   */
  status: MultisigTransactionStatus;
  /**
   * Multisig transaction type
   */
  type: MultisigTransactionType;
  /**
   * Multisig transaction creation datetime in ISO format
   * @example "2021-08-25T12:00:00Z"
   */
  createdAt: string;
  /**
   * Multisig transaction expiration datetime in ISO format
   * @example "2021-08-25T12:00:00Z"
   */
  expiredAt: string;
  /**
   * Multisig transaction execution datetime in ISO format
   * @example "2021-08-25T12:00:00Z"
  */
  executedAt?: string;
  /**
   * List of legal entities which confirmed the multisig transaction
   */
  confirmedBy: BaseLegalEntityWithID[];
}

export class BaseMultisigTransactionListItemModel extends Model {
  title: string;
  creationDateTime: Date;
  expirationDateTime: Date;
  executionDateTime?: Date;
  confirmedBy: SimpleLegalEntityModel[];

  constructor({
    confirmedBy, createdAt, expiredAt, executedAt, type, ...restProps
  }: MultisigTransactionListItemModelProps) {
    super(restProps);
    this.title = MultisigTransactionsChoices[type] || type;
    this.creationDateTime = new Date(createdAt);
    this.expirationDateTime = new Date(expiredAt);
    this.executionDateTime = executedAt ? new Date(executedAt) : undefined;
    this.confirmedBy = SimpleLegalEntityModel.fromResponse(confirmedBy);
  }
}

export class MultisigTransactionListItemModel extends BaseMultisigTransactionListItemModel {
  group: MultisigTransactionTypeGroup;

  constructor({
    type, ...restProps
  }: MultisigTransactionListItemModelProps) {
    super({ type, ...restProps });
    this.group = getMultisigTransactionGroup(type);
  }
}

interface MultisigTransactionDetailModelProps extends MultisigTransactionListItemModelProps {
  /**
   * Unsigned hash of initial transaction
   */
  initUnsignedHash: string;
}

export class MultisigTransactionDetailModel extends BaseMultisigTransactionListItemModel {
  initUnsignedHash: string;
  confirmedByActiveEntity?: boolean;

  constructor({
    initUnsignedHash, confirmedBy, ...restProps
  }: MultisigTransactionDetailModelProps) {
    super({ confirmedBy, ...restProps });
    this.initUnsignedHash = initUnsignedHash;
    this.confirmedByActiveEntity = !!confirmedBy.find((signatory) => signatory.id === actingPersonId);
  }
}

interface MultisigTransactionSigningHistoryModelProps {
  /**
   * Multisig transaction signing history event signatory
   */
  signatory: BaseLegalEntityWithID;
  /**
   * Multisig transaction signing history event action
   */
  action: MultisigSigningHistoryAction;
  /**
   * Multisig transaction signing history event submission datetime in ISO format
   * @example "2021-08-25T12:00:00Z"
   */
  submittedAt: string;
}

export class MultisigTransactionSigningHistoryModel extends Model {
  signatory: SimpleLegalEntityModel;
  submissionDateTime: Date;

  constructor({
    signatory, submittedAt, ...restProps
  }: MultisigTransactionSigningHistoryModelProps) {
    super(restProps);
    this.signatory = SimpleLegalEntityModel.fromResponse(signatory);
    this.submissionDateTime = new Date(submittedAt);
  }
}
