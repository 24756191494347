import { FormikHelpers } from 'formik';
import { isPlainObject, camelCase } from 'lodash';

import { getUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import { getGlobalContext } from 'core/globals';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';

export type KeyConversionMethod = (value?: string) => string;

/**
 * Convert object keys case.
 *
 */
export const convertKeysCase = <T extends object>(
  obj: T,
  conversionMethod: KeyConversionMethod,
): T => Object.entries(obj).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [conversionMethod(key)]: value }),
    {} as T,
  );

export const convertKeysToCamelCase = <T extends object>(obj: T) => convertKeysCase(obj, camelCase);

export const convertNestedKeysCase = <T>(obj: T, conversionMethod: KeyConversionMethod) => {

  if (!conversionMethod || !obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((v) => convertNestedKeysCase(v, conversionMethod));
  }
  const result = convertKeysCase(obj, conversionMethod);
  Object.entries(result).forEach((entry) => {
    const [key, value] = entry;
    if (isPlainObject(value) || Array.isArray(value)) {
      result[key] = convertNestedKeysCase(value, conversionMethod);
    }
  });

  return result;
};

export const convertNestedKeysToCamelCase = <T>(obj: T) => convertNestedKeysCase(obj, camelCase);

export const formatNumber = (value: number, digits = 2) => (
  value.toLocaleString('de-ch', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  })
);

export const toMonetaryValue = (currency: string, value: number | string) => `${currency} ${value}`;

export const txHashResponseHandler = (response?: Record<string, unknown>, responseRedirect = '/') => {
  if (response && 'tx_hash' in response) {
    handleTransactionCreation(response.tx_hash, responseRedirect);
  } else {
    window.location.href = responseRedirect;
  }
};

export const getCurrentlyDisplayedRange = (
  totalPages: number,
  currentPage: number,
  previousRange: { rangeStart: number, rangeEnd: number },
  responseLength: number,
  nextPage: number,
  totalDataLength: number,
): { rangeStart: number; rangeEnd: number } => {

  const { rangeStart, rangeEnd } = previousRange;
  if (nextPage < currentPage && nextPage !== 1) {
    return {
      rangeEnd: rangeStart - 1,
      rangeStart: rangeStart - responseLength,
    };
  }
  if (nextPage === 1) {
    return {
      rangeEnd: responseLength,
      rangeStart: 1,
    };
  }
  if (nextPage === totalPages) {
    return {
      rangeEnd: totalDataLength,
      rangeStart: totalDataLength - responseLength + 1,
    };
  }

  return { rangeStart: rangeEnd + 1, rangeEnd: rangeEnd + responseLength };
};

export const getStringBetweenSubstrings = ({ str, start, end }) => {
  const startSubStrMatch = str.match(start);
  const startSubStr = startSubStrMatch ? startSubStrMatch[0] : '';
  const endSubStrMatch = str.match(end);
  const endSubStr = endSubStrMatch ? endSubStrMatch[0] : '';
  const indexOfStrStart = str.indexOf(startSubStr) + startSubStr.length;
  const indexOfStrEnd = str.indexOf(endSubStr);
  return str.substring(indexOfStrStart, indexOfStrEnd);
};

export const catchHandler = <FormikHelpersType>(
  error: Record<string, unknown>,
  actions: FormikHelpers<FormikHelpersType>,
) => {
  if (error.response) {
    try {
      const { data } = (error.response as Record<string, unknown>);
      parseErrorResponse(data, actions.setFieldError);
    } catch (e) {
      console.error(e);
    }
  } else {
    console.error(error);
  }
  if (actions) {
    actions.setSubmitting(false);
  }
  return Promise.reject();
};

export const redirectTo = (path: string) => { window.location.href = getUrl(path); };

export const openNativeView = (path: string) => {
  const { nativeUrlScheme } = getGlobalContext();
  window.location.href = `${nativeUrlScheme}${path}`;
};
