
import { getCurrentlyDisplayedRange } from 'core/utils';
import CustodialAccountModel from './models';
import convertResponseToModel from '../utils/responseToModel';
import { getUrlWithoutOrigin } from '../utils/urls';

const accountsReducer = (currentState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'fetchFirstPage': {
      const { results, count, next } = data;
      return {
        ...currentState,
        custodialAccounts: convertResponseToModel(results, CustodialAccountModel),
        pageCount: Math.ceil(count / currentState.pageSize),
        currentPageNumber: 1,
        totalAccountsCount: count,
        itemsRange: {
          rangeStart: 1,
          rangeEnd: results.length,
        },
        nextPage: getUrlWithoutOrigin(next),
      };
    }
    case 'fetchPage':
      return {
        ...currentState,
        itemsRange: getCurrentlyDisplayedRange(
          currentState.pageCount,
          currentState.currentPageNumber,
          currentState.itemsRange,
          data.response.length,
          data.pageNumber,
          currentState.totalAccountsCount,
        ),
        custodialAccounts: convertResponseToModel(data.response, CustodialAccountModel),
        currentPageNumber: data.pageNumber,
      };
    case 'fetchNext':
      return {
        ...currentState,
        custodialAccounts: [...currentState.custodialAccounts, ...convertResponseToModel(data.results, CustodialAccountModel)],
        nextPage: getUrlWithoutOrigin(data.next),
      };
    case 'changePageSize':
      return {
        ...currentState,
        pageSize: data,
      };
    default:
      return currentState;
  }
};

export default accountsReducer;
