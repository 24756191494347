import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

import convertResponseToModel from 'utils/responseToModel';
import pricingIcons from '../icons';
import {
  QUOTATION_PERIODS,
  QUOTATION_PERIODS_DISPLAY,
  QUOTATION_TYPE_DISPLAY,
  PRICE_TYPES,
  USAGE_SLABS,
  QUOTATION_TYPE_PLURAL_DISPLAY,
} from '../const';

const matchesExcluded = (value) => !!value.match(/plan-subscription-fee|account-register/g);

// @todo remove together with the model below
export default class OldPricingPlanItemModel {
  constructor({
    id = null,
    name = '',
    price = undefined,
    minPrice = undefined,
    quotationPeriod = '',
    triggerKey = '',
    quotationDisplay = '',
    priceType = '',
    slabs = {},
  }) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.minPrice = minPrice;
    this.quotationPeriod = quotationPeriod;
    this.triggerKey = triggerKey;
    this.quotationDisplay = quotationDisplay;
    this.priceType = priceType;
    this.slabs = slabs;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    quotationPeriod: PropTypes.string,
    triggerKey: PropTypes.string,
    quotationDisplay: PropTypes.string,
    priceType: PropTypes.oneOf(Object.keys(PRICE_TYPES)),
    slabs: PropTypes.shape({}),
  });

  get isSlabBased() {
    return this.priceType === USAGE_SLABS;
  }

  get initSlabPrice() {
    const initSlabKey = Object.keys(this.slabs)[0];
    return this.minPrice || this.slabs[initSlabKey];
  }

  get isFree() {
    return this.price === 0;
  }

  get quotationPeriodDisplay() {
    return QUOTATION_PERIODS[this.quotationPeriod];
  }

  get quotationTypeDisplay() {
    return QUOTATION_TYPE_DISPLAY[this.quotationDisplay];
  }

  get quotationTypePluralDisplay() {
    return QUOTATION_TYPE_PLURAL_DISPLAY[this.quotationDisplay];
  }

  get slabsDescriptions() {
    if (this.isSlabBased) {
      const slabs = [];
      const slabsKeys = Object.keys(this.slabs);
      for (let i = 0; i < slabsKeys.length; i++) {
        let keyString = '';
        let slabPriceDisplay = `${this.slabs[slabsKeys[i]]} CHF
        / ${this.quotationTypeDisplay}
        / ${QUOTATION_PERIODS_DISPLAY[this.quotationPeriod]}
        `;
        if (i === 0) {
          keyString = `1-${slabsKeys[i]}`;
          if (this.minPrice) {
            keyString = gettext(`Minimum ${slabsKeys[i]}`);
            slabPriceDisplay = `${this.minPrice} CHF / ${QUOTATION_PERIODS_DISPLAY[this.quotationPeriod]}`;
          }
        } else if (slabsKeys[i] === 'null') {
          keyString = `${slabsKeys[i - 1]}+`;
        } else {
          keyString = `${parseInt(slabsKeys[i - 1], 10) + 1}-${slabsKeys[i]}`;
        }

        slabs.push({
          amount: keyString,
          price: slabPriceDisplay,
        });
      }
      return slabs;
    }
    return undefined;
  }
}

// @todo remove when registration forms are changed
export class LegacyPricingPlanModel {
  constructor({
    id = null,
    name = '',
    desc = '',
    isCurrent = false,
    isCustom = false,
    tier = null,
    items = [],
    isLast = false,
    previousPlanItems = [],
    previousPlanName = '',
  }) {
    this.id = id;
    this.name = name;
    this.desc = desc;
    this.isCurrent = isCurrent;
    this.isCustom = isCustom;
    this.tier = tier;
    this.items = convertResponseToModel(items, OldPricingPlanItemModel);
    this.isLast = isLast;
    this.previousPlanItems = previousPlanItems;
    this.previousPlanName = previousPlanName;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    desc: PropTypes.string,
    isCurrent: PropTypes.bool,
    isCustom: PropTypes.bool,
    icon: PropTypes.node,
    tier: PropTypes.number,
    items: PropTypes.arrayOf(OldPricingPlanItemModel),
    subscription: PropTypes.string,
    isLast: PropTypes.bool,
    previousPlanItems: PropTypes.arrayOf(OldPricingPlanItemModel),
    previousPlanName: PropTypes.string,
  });

  get icon() {
    return this.isCustom ? pricingIcons.custom : pricingIcons[camelCase(this.name)];
  }

  get subscriptionFee() {
    return this.items.find((item) => item.triggerKey === 'plan-subscription-fee');
  }

  get freeItems() {
    return this.items.filter((item) => item.price === 0 && !matchesExcluded(item.triggerKey));
  }

  get paidItems() {
    return this.items.filter((item) => item.price > 0 && !matchesExcluded(item.triggerKey));
  }

  get paidItemsDifference() {
    return this.paidItems.filter(
      (currentItem) => !this.previousPlanItems.some(
        (previousItem) => currentItem.triggerKey === previousItem.triggerKey,
      ),
    );
  }
}
