import { convertKeysToCamelCase } from 'core/utils';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import { BoardMemberDetailModelProps, BoardMemberListItemModelProps } from 'boardroom/interfaces';

export class BoardMemberListItemModel extends Model {
  id: number;
  avatar: LegalEntityAvatarModel;
  name: string;

  constructor({
    legalEntityId, avatar, name, ...restProps
  }: BoardMemberListItemModelProps) {
    super(restProps);
    this.id = legalEntityId;
    this.name = name;
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
  }
}

export class BoardMemberDetailModel extends BoardMemberListItemModel {
  blockchainAddress: string;
  electionDate: Date;

  constructor({
    address, electionDate, ...restProps
  }: BoardMemberDetailModelProps) {
    super(restProps);
    this.blockchainAddress = address;
    this.electionDate = new Date(electionDate);
  }
}
