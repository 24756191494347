import { BaseLegalEntity } from 'contacts/interfaces/types';
import { CompanySignatoryRole, CompanySignatorySignatureType, CompanySignatoryWeight } from 'signatories/types';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import { CompanySignatorySignatureTypes } from 'signatories/consts';

interface CompanySignatoryListItemModelProps extends BaseLegalEntity {
  /**
   * Legal entity ID
   */
  legalEntityId: number;
  /**
   * Contract relationship ID
   */
  relationshipId: number;
  /**
   * Company signatory role
   */
  role: CompanySignatoryRole;
  /**
   * Company signatory signature type
   */
  signatureType: CompanySignatoryWeight;
}

export class CompanySignatoryListItemModel extends Model {
  id: number;
  avatar: LegalEntityAvatarModel;
  signatureType: CompanySignatorySignatureType;

  constructor({
    relationshipId, avatar, signatureType, ...restProps
  }: CompanySignatoryListItemModelProps) {
    super(restProps);
    this.id = relationshipId;
    this.avatar = LegalEntityAvatarModel.fromResponse(avatar);
    this.signatureType = signatureType === 50 ? CompanySignatorySignatureTypes.COLLECTIVE : CompanySignatorySignatureTypes.SINGLE;
  }
}

interface CompanySignatoryDetailModelProps extends CompanySignatoryListItemModelProps {
  /**
   * Legal entity blockchain address
   */
  address: string;
}

export class CompanySignatoryDetailModel extends CompanySignatoryListItemModel {
  blockchainAddress: string;

  constructor({
    address, ...restProps
  }: CompanySignatoryDetailModelProps) {
    super(restProps);
    this.blockchainAddress = address;
  }
}

