import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useCallback } from 'react';

import { RegisteredObjectDetailView as RegisteredObjectDetail } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import {
  onDetailsEdit,
  onMediaSort,
  onMediaSubmit,
  onRecordAdd,
  onFileDownload,
  fetchObject,
  onCoOwnersSort,
  onCoOwnersSearch,
  fetchCoOwners,
  onAgreementDocumentDownload,
} from './actions';
import reducer from '../reducers';

const RegisteredObjectDetailView = () => {
  const navigate = useNavigate();
  const { catalogType, objectType, objectId } = useParams();

  const [state, dispatch] = useReducer(reducer, { registeredObject: {} });

  const { registeredObject } = state;

  const basePath = `/${catalogType}/${objectType}/`;
  const objectPath = `/services/registered-objects${basePath}${objectId}/`;
  const eventsPath = `${objectPath}events/`;
  const locationsPath = `${objectPath}locations/`;
  const coOwnersPath = `${objectPath}co-owners/`;

  useEffect(() => {
    fetchObject({ objectPath, dispatch });
  }, [objectPath]);

  const coOwnershipQuotasActions = {
    onCreateNew: () => navigate(`${basePath}${objectId}/create-quotas/`),
    onReferenceValueEdit: (values, actions) => onRecordAdd({ values, actions, objectPath, urlPath: eventsPath }),
    onSearch: ({ searchString }, _actions) => onCoOwnersSearch({ search: searchString, url: coOwnersPath }),
    onSort: (column, order) => onCoOwnersSort({ column, order, url: coOwnersPath }),
    onCoOwnersOpen: useCallback(() => fetchCoOwners({ url: coOwnersPath }), [coOwnersPath]),
    onAgreementDownload: (docId) => onAgreementDocumentDownload({
      docId, documents: registeredObject.coOwnership.agreements, objectPath,
    }),
  };

  return (
    <PageContent
      fullscreen
      pageHeading={registeredObject.title || ''}
      headerLeft={<BackButton onClick={() => navigate(basePath)} />}
    >
      <RegisteredObjectDetail
        media={{
          media: registeredObject.media,
          onMediaSort: (ids) => onMediaSort({ updatedIds: ids, objectPath, dispatch }),
          onMediaSubmit: (values, actions) => onMediaSubmit({ values, actions, objectPath }),
        }}
        details={{
          ...registeredObject.details,
          onDetailsEdit: (values, actions) => onDetailsEdit({ values, actions, objectPath }),
        }}
        coOwnershipShares={{
          ...registeredObject.coOwnershipQuotas,
          actions: coOwnershipQuotasActions,
        }}
        events={{
          event: registeredObject.lastEvent,
          onRecordAdd: (values, actions) => onRecordAdd({ values, actions, objectPath, urlPath: eventsPath }),
          onFileDownload: (fileId) => onFileDownload({ obj: registeredObject.lastEvent, fileId, objectPath: eventsPath }),
        }}
        registrationDetails={registeredObject.registrationDetails}
        safekeepingLocation={{
          safekeepingLocation: registeredObject.lastLocation,
          onLocationAdd: (values, actions) => onRecordAdd({ values, actions, objectPath, urlPath: locationsPath }),
          onFileDownload: (fileId) => onFileDownload({ obj: registeredObject.lastLocation, fileId, objectPath: locationsPath }),
        }}
      />
    </PageContent>
  );
};

export default RegisteredObjectDetailView;
