import PropTypes from 'prop-types';
import { omit } from 'lodash';
import * as Yup from 'yup';

import { allTypes } from 'media/constants/documentTypes';
import { convertKeysToCamelCase } from 'core/utils';
import { formatDate, dateToday } from 'utils/date';
import convertResponseToModel from 'utils/responseToModel';
import FileModel from './file';
import BlockchainInfoModel from './blockchainInfo';
import SignerContractModel from './signerContract';


export class DocumentModel {
  constructor({
    id = null,
    documentDate = null,
    docType = '',
    subject = '',
    blockchainInfo = new BlockchainInfoModel({}),
    tags = [],
    files = {},
    signerContract = new SignerContractModel({}),
    relatedObjectId = null,
    relatedObjectType = '',
    icon = {},
    noOfFiles = null,
    subtitle = '',
    title = '',
    status = '',
  }) {
    this.id = parseInt(id, 10) || id;
    this._documentDate = documentDate && !(documentDate instanceof Date) ? new Date(documentDate) : documentDate;
    this.docType = docType;
    this.icon = Object.keys(icon).length > 0 ? icon : { weight: 'regular', name: this.noOfFiles > 1 ? 'copy' : 'file' };
    this.subject = subject;
    this._blockchainInfo = blockchainInfo instanceof BlockchainInfoModel
      ? blockchainInfo
      : new BlockchainInfoModel(convertKeysToCamelCase(blockchainInfo));
    this.tags = tags;
    this._files = files;
    this._signerContract = signerContract instanceof SignerContractModel
      ? signerContract
      : new SignerContractModel(convertKeysToCamelCase(signerContract));
    this.relatedObjectId = relatedObjectId;
    this.relatedObjectType = relatedObjectType;
    this.noOfFiles = noOfFiles;
    this.subtitle = subtitle;
    this.title = title;
    this.status = status;
  }

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  // Related models property getters

  // documentDate property
  get documentDate() { return this._documentDate; }

  set documentDate(documentDate) {
    if (documentDate) {
      this._documentDate = documentDate instanceof Date ? documentDate : new Date(documentDate);
    } else {
      this._documentDate = null;
    }
  }

  // blockchainInfo property
  get blockchainInfo() { return this._blockchainInfo; }

  set blockchainInfo(blockchainInfo) {
    blockchainInfo && this._blockchainInfo.update(convertKeysToCamelCase(blockchainInfo));
  }

  // signerContract property
  get signerContract() { return this._signerContract; }

  set signerContract(signerContractData) {
    signerContractData && this._signerContract.update(convertKeysToCamelCase(signerContractData));
  }

  // files property
  get files() { return this._files; }

  set files(files) {
    const newFilesIdsArray = files.map((file) => file.id);
    const removedFileIds = Object.keys(this._files).filter((item) => !newFilesIdsArray.includes(parseInt(item, 10)));
    if (removedFileIds.length) this._files = omit(this._files, removedFileIds.map(Number));
    files.forEach((file) => {
      if (Object.entries(this._files).length > 0 && file.id in this._files) {
        this._files[file.id].update(convertKeysToCamelCase(file));
      } else {
        this._files[file.id] = new FileModel(convertKeysToCamelCase(file));
      }
    });
  }

  // Properties definitions
  static propType = PropTypes.shape({
    id: PropTypes.number,
    documentDate: PropTypes.instanceOf(Date),
    docType: PropTypes.string,
    icon: PropTypes.shape({
      weight: PropTypes.string,
      name: PropTypes.string,
    }),
    subject: PropTypes.string,
    blockchainInfo: PropTypes.instanceOf(BlockchainInfoModel),
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    files: PropTypes.arrayOf(PropTypes.instanceOf(FileModel)),
    signerContract: PropTypes.instanceOf(SignerContractModel),
    relatedObjectId: PropTypes.number,
    relatedObjectType: PropTypes.string,
    noOfFiles: PropTypes.number,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
  });

  static uploadInitialValues = {
    files: [],
    date: dateToday(),
    subject: '',
    documentType: 'FU',
  };

  static simpleUploadValidation = Yup.object({
    files: Yup.array().of(Yup.mixed()),
    date: Yup.date(),
    subject: Yup.string(),
    documentType: Yup.string(),
  });

  static uploadValidationRequired = Yup.object({
    files: Yup.array().of(Yup.mixed()).required('This field is required'),
    date: Yup.date().required('This field is required'),
    subject: Yup.string().required('This field is required'),
    documentType: Yup.string().required('This field is required'),
  });

  get isEditable() { return this.status === 'DRA'; }

  get formattedDate() { return this.documentDate ? formatDate(this.documentDate) : ''; }

  get typeText() {
    const typeObject = allTypes.find((type) => type.id === this.docType);
    return typeObject ? typeObject.value : gettext('Document');
  }
}

export class SimpleDocumentModel {
  constructor({
    id = null,
    createdAt = null,
    date = null,
    docType = '',
    subject = '',
    files = [],
    status = '',
  }) {
    this.id = id;
    this.date = new Date(date || createdAt);
    this.docType = docType;
    this.subject = subject || gettext('Document');
    this.files = convertResponseToModel(files || [], FileModel);
    this.status = status;
  }

  static simpleUploadValidation = Yup.object({
    files: Yup.array().of(Yup.mixed()),
    date: Yup.date(),
    subject: Yup.string(),
  });

  static fromResponse(data) {
    const convertedCase = convertKeysToCamelCase(data);
    return new SimpleDocumentModel(Object.fromEntries(
      Object.entries(convertedCase).filter(([_, v]) => (v != null)), // remove null values before converting to model
    ));
  }

  static fromArrayResponse(data) {
    return data.map((doc) => this.fromResponse(doc));
  }
}
