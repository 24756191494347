/* Free Items List */
import PropTypes from 'prop-types';

import { icons } from 'core/icons';
import { INITIAL_TWC_AMOUNT } from '../const';


const FreeItemsList = ({ planItems, isCompany }) => (
  <ul className="pricing-plan-items">
    {!isCompany && (
    <li className="pricing-plan-item">
      {icons.checkMark}
      <span className="label">
        {gettext(`Upon registration you get ${INITIAL_TWC_AMOUNT} CREDITS on the balance.`)}
      </span>
    </li>
    )}
    {planItems.map((planItem) => (
      <li className="pricing-plan-item" key={planItem.id}>
        {icons.checkMark}
        <span className="label">{planItem.name}</span>
      </li>
    ))}
  </ul>
);

FreeItemsList.defaultProps = {
  isCompany: false,
};

FreeItemsList.propTypes = {
  planItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isCompany: PropTypes.bool,
};

export default FreeItemsList;
