import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { getApiUrl, getUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { isNativeAppWebview } from 'utils/general';

export const getNativeFileUrl = (url, title, mediaType, blockchainDatetime, keccak256) => {
  const queryArgs = {
    uri: url,
    title,
    media_type: mediaType,
  };
  if (blockchainDatetime) {
    queryArgs.blockchain_datetime = blockchainDatetime;
  }
  if (keccak256) {
    queryArgs.keccak256 = keccak256;
  }
  const urlParams = new URLSearchParams(queryArgs);
  const { nativeUrlScheme } = getGlobalContext();
  return new URL(`${nativeUrlScheme}document?${urlParams}`).href;
};

export const getDocumentData = (values, isUpdate = false) => {
  const data = {};
  const filesCopy = [...values.files];
  if (isUpdate) {
    data.files = filesCopy.filter(({ id }) => !!id);
    // when editing a document new documents have ids set to a stringified timestamp
    // to have a unique identifier when adding or removing them, such document ids should not be submitted
    if (typeof values.id !== 'string') {
      data.id = values.id;
    }
  }
  data.doc_type = values.type || 'FU';
  data.status = values.status || 'DRA';
  data.date = (values.date instanceof Date) ? values.date.toISOString().split('T')[0] : values.date;
  data.subject = values.subject;
  data.new_files = filesCopy.filter((file) => file instanceof File);
  return data;
};

export const getDocumentSubmitData = (values, isUpdate = false) => {
  const submitData = new FormData();
  const document = getDocumentData(values, isUpdate);
  const { files, new_files: newFiles, ...restData } = document;

  Object.entries(convertKeysCase(restData, snakeCase)).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  files && files.forEach((file, fileIndex) => {
    Object.entries(file).forEach(([key, value]) => {
      submitData.append(`files[${fileIndex}]${snakeCase(key)}`, value);
    });
  });

  newFiles.forEach((file, index) => { submitData.append(`new_files[${index}]`, file); });

  return submitData;
};

export const addMultipleDocuments = ({ documents, submitData, isUpdate = false, fieldName = 'documents' }) => {
  const docs = documents.map((doc) => getDocumentData(doc, isUpdate));
  docs.forEach((doc, docIndex) => {
    const { new_files: newFiles, files, ...data } = doc;
    Object.entries(data).forEach(
      ([key, value]) => submitData.append(`${fieldName}[${docIndex}]${key}`, value),
    );
    newFiles && newFiles.forEach((newFile, newFileIndex) => {
      submitData.append(`${fieldName}[${docIndex}]new_files[${newFileIndex}]`, newFile);
    });
    files && files.forEach((file, fileIndex) => {
      Object.entries(file).forEach(([key, value]) => {
        submitData.append(`${fieldName}[${docIndex}]files[${fileIndex}]${snakeCase(key)}`, value);
      });
    });
  });
  return submitData;
};

export const onPdfDownload = (downloadLink, title) => {
  window.location = isNativeAppWebview
    ? getNativeFileUrl(downloadLink, `${title}.pdf`, 'application/pdf')
    : downloadLink;
};

const agreements = {
  user: 'user_agreement',
  corporate: 'corporate_user_agreement',
};
export const onAgreementDownload = (type) => onPdfDownload(getUrl(`/agreements/${type}/`), agreements[type]);

export const onFileDownload = (fileId, documents, urlBase) => {
  let files = [];
  documents.forEach((document) => { files = [...files, ...document.files]; });
  const file = files.find((docFile) => docFile.id === fileId);
  window.location = isNativeAppWebview ? file.nativeUrl : getApiUrl(`${urlBase}files/${fileId}/download/`);
};
