import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { APP_CONTACT_LINK } from 'pricing/const';
import { PRODUCT_OFFERINGS_BASE_PATH } from './consts';

export const onSubscribe = (planId, productOfferingId, dispatch) => (
  axios
    .post(getApiUrl(`${PRODUCT_OFFERINGS_BASE_PATH}subscriptions/${productOfferingId}/${planId}/`))
    .then(({ data }) => dispatch({ type: 'updateSubscribedPlan', data: { ...data, offeringId: productOfferingId } }))
    .catch(console.error)
);

export const onApplicationUpdate = (urlPath, dispatch, productOfferingId, isWithdrawal) => {
  const method = isWithdrawal ? 'delete' : 'post';
  return (
    axios[method](getApiUrl(urlPath))
      .then(({ data }) => dispatch({ type: 'updateSubscriptionStatus', data: { ...data, offeringId: productOfferingId } }))
      .catch(console.error)
  );
};

export const onContact = () => { window.open(APP_CONTACT_LINK, '_blank'); };
