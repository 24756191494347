import { catchHandler } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import LegalEntityPricingPlanModel from 'pricing/models/legalEntityPricingPlan';
import convertResponseToModel from 'utils/responseToModel';

export const fetchSubscriptions = (noOfItems) => {
  const queryArgs = noOfItems ? { no_of_items: noOfItems } : '';
  return (
    axios
      .get(getApiUrl('/product-offerings/subscriptions/', queryArgs))
      .then(({ data }) => {
        const subscriptions = convertResponseToModel(data, LegalEntityPricingPlanModel);
        return subscriptions.map((subscription) => ({ ...subscription, offering: subscription.offering }));
      })
      .catch((error) => catchHandler(error))
  );
};
